<template>
  <svg style="width: 0; height: 0; position: absolute;">
    <symbol id="icon-rules" viewBox="0 0 16 16">
      <path fill="#131c27" d="M8 1C4.13396 1 1 4.13396 1 8C1 11.866 4.13396 15 8 15C11.866 15 15 11.866 15 8C15 4.13396 11.866 1 8 1ZM8.20592 11.6633C8.07437 11.8051 7.88771 11.876 7.64475 11.876C7.40208 11.876 7.21571 11.8068 7.08592 11.6683C6.95612 11.5297 6.89108 11.3358 6.89108 11.0864C6.89108 10.5702 7.14221 10.3123 7.64475 10.3123C7.89092 10.3123 8.07875 10.3797 8.20883 10.5148C8.33892 10.6498 8.40367 10.8403 8.40367 11.0864C8.40367 11.3291 8.33746 11.5213 8.20592 11.6633ZM10.0341 6.65921C9.95883 6.85929 9.84508 7.04975 9.69283 7.23088C9.54058 7.412 9.27867 7.64796 8.90621 7.93846C8.58829 8.18783 8.37537 8.39462 8.26775 8.55883C8.17675 8.69737 8.12687 8.8785 8.11287 9.09754C8.112 9.10221 8.11054 9.10367 8.10967 9.10833C8.04842 9.45337 7.64767 9.45104 7.64767 9.45104H7.43708C7.43708 9.45104 7.08854 9.39621 7.10167 9.12292C7.10167 8.74696 7.16992 8.43429 7.30671 8.18462C7.4435 7.93496 7.68296 7.67712 8.0245 7.41054C8.43137 7.08912 8.69329 6.83975 8.81112 6.66212C8.92896 6.4845 8.98817 6.27246 8.98817 6.02629C8.98817 5.73929 8.8925 5.51879 8.70117 5.36479C8.50983 5.21079 8.2345 5.13408 7.87575 5.13408C7.55083 5.13408 7.25012 5.18017 6.97333 5.27262C6.82225 5.32308 6.67379 5.38025 6.5265 5.44179C6.52329 5.44296 6.52212 5.44237 6.51862 5.44383C6.17854 5.57538 6.02075 5.30792 6.02075 5.30792L5.88717 5.02821C5.88658 5.02646 5.73667 4.68492 6.07092 4.53471C6.66679 4.26229 7.29708 4.12462 7.96267 4.12462C8.63233 4.12462 9.16404 4.28883 9.55692 4.61696C9.94979 4.94508 10.1464 5.39804 10.1464 5.97554C10.1467 6.23133 10.109 6.45942 10.0341 6.65921Z" />
    </symbol>
    <symbol id="icon-usd-small" viewBox="0 0 32 32">
      <g clip-path="url(#clip0)">
        <path d="M31.523 19.87c-2.137 8.572-10.82 13.788-19.393 11.651C3.56 29.384-1.657 20.702.48 12.131 2.617 3.558 11.3-1.659 19.871.478c8.573 2.137 13.79 10.82 11.652 19.392z" fill="#1D8200"/>
        <path d="M3.392 12.857h0c1.736-6.965 8.79-11.204 15.753-9.468h0c6.965 1.737 11.204 8.791 9.467 15.756-1.736 6.963-8.79 11.202-15.756 9.465-6.963-1.736-11.201-8.79-9.464-15.753z" stroke="#fff" stroke-opacity=".24" stroke-width="6"/>
        <path d="M31.046 10.564L.206 18.565a15.94 15.94 0 01.273-6.434C2.616 3.56 11.298-1.659 19.87.48c5.37 1.338 9.423 5.245 11.177 10.085z" fill="#fff" opacity=".3"/>
        <g filter="url(#filter0_di)">
          <path d="M28.61 19.145c-1.736 6.964-8.79 11.202-15.756 9.466-6.963-1.737-11.202-8.79-9.465-15.755C5.125 5.892 12.18 1.654 19.143 3.39c6.966 1.736 11.204 8.791 9.468 15.756z" fill="#1D8101"/>
        </g>
        <path d="M21.635 18.977c0 1.088-.356 1.974-1.066 2.659-.71.684-1.688 1.127-2.933 1.33V24.5h-2.845v-1.506c-.984-.136-1.798-.423-2.443-.86-.64-.436-1.108-.958-1.408-1.564a4.428 4.428 0 01-.44-1.935h3.099c0 .56.215 1.01.645 1.349.437.332 1.046.498 1.828.498.847 0 1.463-.124 1.848-.371.384-.248.577-.63.577-1.144 0-.3-.102-.55-.303-.753-.196-.202-.483-.371-.86-.508-.379-.143-.88-.287-1.506-.43a16.193 16.193 0 01-2.483-.792c-.704-.3-1.3-.73-1.79-1.29-.482-.561-.723-1.278-.723-2.151 0-1.043.336-1.903 1.007-2.581.671-.684 1.656-1.108 2.952-1.27V7.5h2.845v1.77c1.167.228 2.047.693 2.64 1.397.6.698.9 1.578.9 2.64h-3.1c0-.424-.172-.776-.518-1.056-.339-.287-.883-.43-1.633-.43-1.329 0-1.994.414-1.994 1.241 0 .28.101.522.303.724.202.195.518.368.949.518.43.143 1.003.283 1.72.42 1.48.3 2.636.782 3.47 1.447.841.658 1.262 1.594 1.262 2.806z" fill="#fff"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h32v32H0z"/>
        </clipPath>
        <filter id="filter0_di" x="2" y="3" width="27" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dx="-1" dy="1"/>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.32 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="-1" dy="2"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
          <feBlend in2="shape" result="effect2_innerShadow"/>
        </filter>
      </defs>
    </symbol>
    <symbol id="icon-lucky-small" viewBox="0 0 64 64" fill="none">
      <g clip-path="url(#clip0123_2)">
          <path d="M63.0461 39.7411C58.7716 56.8841 41.4068 67.3171 24.26 63.0421C7.12012 58.7681 -3.31399 41.4041 0.962469 24.2621C5.23492 7.11711 22.5998 -3.31689 39.7416 0.957107C56.8874 5.23111 67.3205 22.5971 63.0461 39.7411Z" fill="#4ac481"/>
          <path d="M3.87326 24.9883L3.87345 24.9875C7.74523 9.45037 23.4818 -0.00514295 39.0158 3.86799L39.016 3.86803C54.5539 7.74122 64.0089 23.4788 60.1352 39.0153C56.2617 54.5504 40.5252 64.0055 24.9857 60.1312C9.45344 56.258 -0.00205251 40.5223 3.87326 24.9883Z" stroke="white" stroke-opacity="0.24" stroke-width="6"/>
          <g style="mix-blend-mode: screen" opacity="0.3">
              <path d="M62.0911 21.1272L0.412762 37.1302C-0.264382 32.9715 -0.12597 28.6099 0.958563 24.2626C5.23102 7.1176 22.5959 -3.3164 39.7377 0.957596C50.4776 3.63476 58.5837 11.4487 62.0911 21.1272Z" fill="white"/>
          </g>
          <g filter="url(#filter0_di111_2)">
              <path d="M57.2218 38.2897C53.7488 52.2183 39.6398 60.695 25.708 57.2216C11.7819 53.749 3.30421 39.6409 6.77883 25.7132C10.2502 11.783 24.3591 3.30545 38.2869 6.77804C52.2179 10.2506 60.6948 24.3604 57.2218 38.2897Z" fill="#4ac481"/>
          </g>
          <g transform="translate(16.5, 15), scale(0.74)">
            <path fill="#e2ffed" d="M21.234,20.375c0.47,0.468,1.228,0.468,1.697,0c2.327-2.315,8.646-8.604,8.775-8.733c2.662-2.663,2.662-6.98,0-9.644
              c-2.655-2.656-6.96-2.663-9.624-0.019c-2.665-2.645-6.967-2.638-9.624,0.019c-2.662,2.663-2.662,6.98,0,9.644
              C12.589,11.772,18.908,18.06,21.234,20.375z"/>
            <path fill="#e2ffed" d="M22.932,23.791c-0.469-0.467-1.229-0.467-1.697,0.001c-2.327,2.314-8.646,8.604-8.775,8.732
              c-2.662,2.663-2.662,6.98,0,9.645c2.657,2.656,6.959,2.663,9.624,0.019c2.664,2.646,6.969,2.639,9.624-0.019
              c2.662-2.663,2.662-6.98,0-9.645C31.577,32.396,25.259,26.106,22.932,23.791z"/>
            <path fill="#e2ffed" d="M42.168,12.459c-2.662-2.663-6.979-2.662-9.645-0.001c-0.131,0.129-6.419,6.448-8.733,8.774
              c-0.467,0.47-0.467,1.228,0.001,1.697c2.314,2.328,8.604,8.648,8.732,8.777c2.662,2.662,6.981,2.662,9.645,0.001
              c2.655-2.655,2.662-6.96,0.018-9.625C44.83,19.418,44.825,15.116,42.168,12.459z"/>
            <path fill="#e2ffed" d="M20.376,22.932c0.467-0.47,0.467-1.228,0-1.697c-2.315-2.327-8.605-8.646-8.733-8.775
              c-2.664-2.663-6.98-2.663-9.644-0.001c-2.656,2.657-2.663,6.959-0.02,9.625c-2.644,2.664-2.637,6.969,0.02,9.624
              c2.664,2.663,6.979,2.662,9.644,0.001C11.772,31.578,18.061,25.259,20.376,22.932z"/>
          </g>
      </g>
      <defs>
          <filter id="filter0_di111_2" x="5" y="6" width="53" height="54" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dx="-1" dy="1"/>
              <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.32 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="-1" dy="2"/>
              <feGaussianBlur stdDeviation="1"/>
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow"/>
          </filter>
          <filter id="filter1_d222_2" x="16" y="14" width="29" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dx="-1" dy="1"/>
              <feGaussianBlur stdDeviation="0.5"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <clipPath id="clip0123_2">
              <rect width="64" height="64" fill="white"/>
          </clipPath>
      </defs>
    </symbol>
  </svg>
</template>
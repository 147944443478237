<template>
  <main>
    <div class="bg" />
    <section class="write">
      <div class="row">
        <div class="block">
          <h2>Dividends Pool</h2>
          <div class="form-control">
            <label><i v-tooltip="`Initial Dividends Pool in USDT`"><svg><use xlink:href="#icon-rules"></use></svg></i>Initial Dividends Pool <svg><use xlink:href="#icon-usd-small"></use></svg></label>
            <number v-model="pool.initialDividends" v-bind="usdtFormat" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Dividends distribution periodicity`"><svg><use xlink:href="#icon-rules"></use></svg></i>Dividends paid every {{ pool.dividendsPaidPeriod }} (hours)</label>
            <input v-model="pool.dividendsPaidPeriod" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Which percent of pool will be distributed per each iteration`"><svg><use xlink:href="#icon-rules"></use></svg></i>Percent of pool paid daily (%)</label>
            <input v-model="pool.percentPaid" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`How many Lucky tokens required to receive dividends`"><svg><use xlink:href="#icon-rules"></use></svg></i>Min tokens to get divs <svg><use xlink:href="#icon-lucky-small"></use></svg></label>
            <number v-model="pool.minTokensAmountToReceiveDividends" v-bind="luckyFormat" />
          </div>
        </div>
        <div class="block">
          <h2>Token and Mining</h2>
          <div class="form-control">
            <label><i v-tooltip="`How many tokens can be mined`"><svg><use xlink:href="#icon-rules"></use></svg></i>Total Supply <svg><use xlink:href="#icon-lucky-small"></use></svg></label>
            <number v-model="token.totalSupply" v-bind="luckyFormat" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Wager in USDT required to receive 1 Lucky token`"><svg><use xlink:href="#icon-rules"></use></svg></i>Initial Mining Price <svg><use xlink:href="#icon-usd-small"></use></svg></label>
            <number v-model="token.initialMiningPrice" v-bind="usdtFormat" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Mining wager required to mine 1 Lucky token increases by ${ token.miningPriceStepPercent }% every step`"><svg><use xlink:href="#icon-rules"></use></svg></i>Mining dificulty step (%)</label>
            <input v-model="token.miningPriceStepPercent" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Every ${token.miningPriceStep} mined tokens mining price will increase by ${ token.miningPriceStepPercent }%`"><svg><use xlink:href="#icon-rules"></use></svg></i>Mining price step <svg><use xlink:href="#icon-lucky-small"></use></svg></label>
            <number v-model="token.miningPriceStep" v-bind="luckyFormat" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`How many Lucky tokens are mined in addition to each mined token`"><svg><use xlink:href="#icon-rules"></use></svg></i>Emission per 1 mined <svg><use xlink:href="#icon-lucky-small"></use></svg></label>
            <number v-model="token.emissionPer1Mined" v-bind="luckyFormat" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`How many Lucky tokens does the team burn when the user burns 1 Lucky token`"><svg><use xlink:href="#icon-rules"></use></svg></i>Team burn per 1 <svg><use xlink:href="#icon-lucky-small"></use></svg></label>
            <number v-model="token.burningByTeamAutoPer1UserBurned" v-bind="luckyFormat" />
          </div>
        </div>
        <div class="block">
          <h2>Bets</h2>
          <div class="form-control">
            <label><i v-tooltip="`Percent of negative mathematical expectation`"><svg><use xlink:href="#icon-rules"></use></svg></i>House Edge (%)</label>
            <input v-model="bets.houseEdge" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`The minimum size of one bet that needs to be made in order to mine part of the Lucky token`"><svg><use xlink:href="#icon-rules"></use></svg></i>Min bet amount for mining <svg><use xlink:href="#icon-usd-small"></use></svg></label>
            <number v-model="bets.minAmountForMining" v-bind="usdtFormat" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Minimum bet amount in USDT`"><svg><use xlink:href="#icon-rules"></use></svg></i>Min bet amount <svg><use xlink:href="#icon-usd-small"></use></svg></label>
            <number v-model="bets.minAmount" v-bind="usdtFormat" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Maximum bet amount in USDT`"><svg><use xlink:href="#icon-rules"></use></svg></i>Max bet amount <svg><use xlink:href="#icon-usd-small"></use></svg></label>
            <number v-model="bets.maxAmount" v-bind="usdtFormat" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Maximum payout for 1 bet in USDT`"><svg><use xlink:href="#icon-rules"></use></svg></i>Max bet payout <svg><use xlink:href="#icon-usd-small"></use></svg></label>
            <number v-model="bets.maxPayout" v-bind="usdtFormat" />
          </div>
        </div>
        <div class="block">
          <h2>Users</h2>
          <div class="form-control">
            <label><i v-tooltip="`How many users have donated crypto`"><svg><use xlink:href="#icon-rules"></use></svg></i>Users count</label>
            <input v-model="users.count" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Percent of users who don't care about Lucky tokens`"><svg><use xlink:href="#icon-rules"></use></svg></i>Will lost all lucky tokens (Users %)</label>
            <input v-model="users.percentWhoWillLostTokens" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Count of users who have deposited a lot of crypto`"><svg><use xlink:href="#icon-rules"></use></svg></i>Whales count</label>
            <input v-model="users.whalesCount" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Users average bets count daily`"><svg><use xlink:href="#icon-rules"></use></svg></i>Users average bets count daily</label>
            <input v-model="users.averageBetsCount" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Users average bet amount in USDT`"><svg><use xlink:href="#icon-rules"></use></svg></i>Users average bet <svg><use xlink:href="#icon-usd-small"></use></svg></label>
            <number v-model="users.averageBet" v-bind="usdtFormat" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Whales average bet amount in USDT`"><svg><use xlink:href="#icon-rules"></use></svg></i>Whales average bet <svg><use xlink:href="#icon-usd-small"></use></svg></label>
            <number v-model="users.whalesAverageBet" v-bind="usdtFormat" />
          </div>
          <div class="form-control">
            <label><i v-tooltip="`Percent of new registrations without invitation`"><svg><use xlink:href="#icon-rules"></use></svg></i>Have no inviter (Users %)</label>
            <input v-model="users.percentRegistrationsWithoutReferral" />
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="read">
      <h1>Simulation</h1>
      <div class="block">
        <div class="form-control">
          <label><i v-tooltip="``"><svg><use xlink:href="#icon-rules"></use></svg></i>Current Dividends Pool</label>
          <input readonly v-model="currentDividends" />
        </div>
        <div class="form-control">
          <label><i v-tooltip="``"><svg><use xlink:href="#icon-rules"></use></svg></i>Token Optimal Price if Mine <svg><use xlink:href="#icon-usd-small"></use></svg></label>
          <input readonly v-model="optimalPriceIfMine" />
        </div>
      </div>
    </section> -->
  </main>
</template>

<script setup>
/* eslint-disable no-unused-vars */

import { computed, reactive } from 'vue'

const usdtFormat = { decimal: '.', separator: ' ', prefix: '', suffix: ' USDT', precision: 2, nullValue: '', masked: false, reverseFill: false, prefill: false }
const luckyFormat = { decimal: '.', separator: ' ', prefix: '', suffix: ' Lucky', precision: 2, nullValue: '', masked: false, reverseFill: false, prefill: false }

const initialState = {
  token: {
    totalSupply: 5000000000, /* Сколько всего токенов в обороте может быть */
    initialMiningPrice: 1, /* Оборот в долларах необходимый для получения 1 токена */
    miningPriceStepPercent: 5, /* Насколько процентов растёт сложность майнинга на следующем шаге */
    miningPriceStep: 50000000, /* Количество добытых токенов после которого сложность майнинга возрастает */
    emissionPer1Mined: 1, /* Сколько токенов майнится с каждым добытым токеном */
    burningByTeamAutoPer1UserBurned: 1 /* Сколько токенов сжигает команда когда юзер сжигает 1 */
  },
  pool: {
    initialDividends: 3000000, /* Первоначальный пул дивидендов */
    dividendsPaidPeriod: 24, /* Частота выплат дивидендов холдерам токена */
    percentPaid: 3, /* Процент пула который распределяется за 1 раз */
    minTokensAmountToReceiveDividends: 10, /* Минимальное количество токенов для получения дивидендов */
  },
  bets: {
    houseEdge: 4, /* Негативное математическое ожидание */
    minAmountForMining: 0.01, /* Минимальный размер одной ставки которую нужно сделать чтобы намайнить часть токена */
    minAmount: 0.001, /* Минимальная ставка */
    maxAmount: 3000, /* Максимальная ставка */
    maxPayout: 73500, /* Максимальная выплата в случае победы */
  },
  users: {
    count: 1000, /* Количество обычных пользователей */
    whalesCount: 10, /* Количество китов */
    averageBetsCount: 100, /* Среднее количество ставок в сутки от 1 игрока */
    averageBet: 10, /* Средняя ставка у игрока */
    whalesAverageBet: 500, /* Средняя ставка у кита */
    percentWhoWillLostTokens: 50, /* Процент пользователей которые в итоге проиграют все добытые ими токены */
    percentRegistrationsWithoutReferral: 40, /* Процент пользователей которые сами создали аккаунт, без реферальной ссылки */
  },
}

const token = reactive(initialState.token)
const pool = reactive(initialState.pool)
const bets = reactive(initialState.bets)
const users = reactive(initialState.users)

 /* Если Referral нет, то стрим перенаправляется на Team */
const streams = [
  { name: 'Team', percent: 25, limit: Infinity, end: false, account: 1 },
  { name: 'Investor', percent: 25, limit: 200000000, end: false, account: 5 },
  { name: 'Marketing', percent: 16, limit: Infinity, end: false, account: 6 },
  { name: 'Airdrops', percent: 4, limit: Infinity, end: false, account: 7 },
  { name: 'Jackpots', percent: 10, limit: Infinity, end: false, account: 8 },
  { name: 'Referral', percent: 10, limit: Infinity, end: false, account: 0 },
  { name: 'Reserve', percent: 10, limit: Infinity, end: false, account: 10 },
]

const accounts = [
  { id: 0, name: 'Referral', role: 'Всегда разный id, за привлечение игрока', balance: 0, tokens: 0, withdrawn: 0 },
  { id: 1, name: 'Team', role: 'Команда', balance: 0, tokens: 0, withdrawn: 0 },
  { id: 5, name: 'Investor', role: 'Seed Investor', balance: 0, tokens: 0, withdrawn: 0 },
  { id: 6, name: 'Marketing', role: 'На рекламу', balance: 0, tokens: 0, withdrawn: 0 },
  { id: 7, name: 'Airdrops', role: 'На конкурсы и баунти', balance: 0, tokens: 0, withdrawn: 0 },
  { id: 8, name: 'Jackpots', role: 'Джекпоты в играх', balance: 0, tokens: 0, withdrawn: 0 },
  { id: 10, name: 'Reserve', role: 'Резерв', balance: 0, tokens: 0, withdrawn: 0 },
]

const state = reactive({
  miningProgressPercent: 0,
  totalUsersTokens: 0,
  totalTokensInCirculation: 0,
  currentMiningPrice: 1,
  totalMined: 0,
  totalBurned: 0,
  totalBurnedInGames: 0,
  totalBurnedInOtherMechanics: 0,
  burnedInGamesDaily: 0,
  burnedInOtherMechanicsDaily: 0,
  totalBurnedByTeam: 0,
  totalBurnedByPlayers: 0,
  totalTokenHolders: 0,
  currentDividends: 0,
})

/* ============ Тут идут уже Read Only поля ============ */

/* Оптимальная цена добычие токена при оптимальной стратегии */
const optimalPriceIfMine = computed(() => token.initialMiningPrice)

/* Соотношение в текущий день проигранных денег к планируемой выплате */
const ratioLostTodayToPlannedPayout = computed(() => { return 0 })

/* Цена добычи последних токенов */
const miningPriceAtTheEnd = computed(() => { return 0 })

/* Выплачивается USD на 1 Lucky токен */
const payoutPer1Token = computed(() => { return 0 })

/* Следующая сумма дивидендов */
const nextPayoutFromPool = computed(() => { return 0 })

/* ============ Функции для управления симуляцией ============ */

function profitFromTokens(amount) {
  return payoutPer1Token.value * amount
}

function nextDay() {
  // Начисляет на все аккаунты дивиденды исходя из их токенов
}

function burn() {
  // Вначале снимаются токены с резерв, если там нет, то равномерно со всех аккаунтов которые сейчас в streams
}
function userWinsToken() {
  // Вначале снимаются токены с резерв, если там нет, то равномерно со всех аккаунтов которые сейчас в streams
}

function addAccount() {

}
function addStream() {

}

function editStream() {

}
function editAccount() {

}

function transferFromAccount() {

}
function withdrawFromAccount() {

}

function removeStream() {

}
function removeAccount() {

}

function getStreamName(accountId) {

}
</script>

<style lang="sass" scoped>
main
  display: flex
  max-width: 1400px
  flex-direction: column
  margin: auto
  margin-top: 30px
  font-family: 'Roboto', sans-serif

  .block
    margin: 20px 0
    padding: 22px 26px
    height: fit-content
    background: #fff
    border-radius: 6px

  .row
    display: flex
    justify-content: space-between

  h2
    color: #131c27
    font-size: 20px
    font-weight: 500
    margin-bottom: 18px

.form-control
  display: flex
  flex-direction: column
  margin-bottom: 18px
  width: 270px
  position: relative

  label
    color: #131c27
    font-size: 15px
    font-weight: 500
    margin-bottom: 8px
    cursor: pointer
    display: flex
    align-items: center
    padding-left: 20px

    > svg
      width: 20px
      height: 20px
      margin-left: 4px
      position: absolute
      right: 6px
      margin-top: 62px

    i
      width: 16px
      height: 16px
      position: absolute
      margin-top: -1px
      margin-left: -20px
      display: block

      svg
        width: 16px
        height: 16px
        opacity: .7

        &:hover
          opacity: 1

  input
    border-radius: 4px
    background: #edeef0
    height: 32px
    padding: 0 10px
    font-size: 15px
    color: #131c27
    font-weight: 500
    letter-spacing: 0
    border: 0
    outline: none
    padding-bottom: 1px
    box-shadow: inset 0 0 8px 1px #0000000d

.bg
  background-image: url(https://cdn.yablonev.art/background.jpg)
  background-size: cover
  background-attachment: fixed
  width: 100%
  height: 100%
  z-index: -1

.bg:before
  content: ''
  z-index: 0
  top: 0px
  background: #000
  opacity: .24
  pointer-events: none

.bg, .bg:before
  position: fixed
  left: 0
  right: 0
  bottom: 0
</style>
import { createApp } from 'vue'
import App from '@/App'

import { setupTippy } from 'v-tippy-3'
import number from '@coders-tm/vue-number-format'

const app = createApp(App)
app.use(number, { precision: 4 })
app.use(setupTippy({ globalDirectives: true, arrow: true, animation: 'scale' }))

app.mount('#app')